var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import s from './Footer.module.scss';
var Footer = function () {
    return (_jsx("footer", __assign({ className: s.footer }, { children: _jsx("p", { children: "Copyright \u00A9 2024 \u041F\u043E\u0440\u0442\u0430\u043B \u043E\u0431\u0443\u0447\u0435\u043D\u0438\u044F koreanbutik" }) })));
};
export default Footer;
