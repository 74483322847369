import { object, string } from 'zod';
export var registerSchema = object({
    first_name: string()
        .nonempty('Заполните поле имя')
        .min(2, 'Имя должно содержать минимум 2 символа'),
    last_name: string()
        .nonempty('Заполните поле фамилия')
        .min(2, 'Фамилия должна содержать минимум 2 символа'),
    email: string()
        .nonempty('Заполните поле email')
        .email('Корректно заполните email'),
    password: string()
        .nonempty('Заполните пароль')
        .min(6, 'Пароль должен содержать минимум 6 символов'),
    confirm_password: string().nonempty('Повторите пароль'),
}).refine(function (data) { return data.password === data.confirm_password; }, {
    message: 'Пароли не совпадают',
    path: ['confirm_password'],
});
