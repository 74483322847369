var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './UserResults.module.scss';
import { useNotification } from '../../../NotificationContext';
import { saveAs } from 'file-saver'; // Импортируем библиотеку для сохранения файлов
import { Skeleton } from '../../../Skeleton';
var UserResults = function () {
    var _a = useState([]), users = _a[0], setUsers = _a[1];
    var _b = useState([]), userResults = _b[0], setUserResults = _b[1];
    var _c = useState([]), userArticles = _c[0], setUserArticles = _c[1];
    var _d = useState([]), allTests = _d[0], setAllTests = _d[1];
    var _e = useState([]), allArticles = _e[0], setAllArticles = _e[1];
    var _f = useState([]), roles = _f[0], setRoles = _f[1];
    var _g = useState(true), loading = _g[0], setLoading = _g[1];
    var _h = useState(null), error = _h[0], setError = _h[1];
    var _j = useState(null), selectedUserEmail = _j[0], setSelectedUserEmail = _j[1];
    var _k = useNotification(), notifySuccess = _k.notifySuccess, notifyError = _k.notifyError;
    useEffect(function () {
        var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var _a, usersResponse, testsResponse, rolesResponse, allTestsResponse, allArticlesResponse, usersData, testsData_1, rolesData, allTestsData, allArticlesData, userArticlesData, mergedResults, sortedUsers, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, Promise.all([
                                axios.get('/api/v1/users/', {
                                    headers: {
                                        Authorization: "Bearer ".concat(localStorage.getItem('accessToken'))
                                    }
                                }),
                                axios.get('/api/v1/users-tests/', {
                                    headers: {
                                        Authorization: "Bearer ".concat(localStorage.getItem('accessToken'))
                                    }
                                }),
                                axios.get('/api/v1/roles/', {
                                    headers: {
                                        Authorization: "Bearer ".concat(localStorage.getItem('accessToken'))
                                    }
                                }),
                                axios.get('/api/v1/tests/', {
                                    headers: {
                                        Authorization: "Bearer ".concat(localStorage.getItem('accessToken'))
                                    }
                                }),
                                axios.get('/api/v1/articles/', {
                                    headers: {
                                        Authorization: "Bearer ".concat(localStorage.getItem('accessToken'))
                                    }
                                })
                            ])];
                    case 1:
                        _a = _b.sent(), usersResponse = _a[0], testsResponse = _a[1], rolesResponse = _a[2], allTestsResponse = _a[3], allArticlesResponse = _a[4];
                        usersData = usersResponse.data;
                        testsData_1 = testsResponse.data;
                        rolesData = rolesResponse.data;
                        allTestsData = allTestsResponse.data;
                        allArticlesData = allArticlesResponse.data;
                        return [4 /*yield*/, Promise.all(usersData.map(function (user) { return __awaiter(void 0, void 0, void 0, function () {
                                var response;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, axios.get("/api/v1/get_read_articles/".concat(user.id, "/"), {
                                                headers: {
                                                    Authorization: "Bearer ".concat(localStorage.getItem('accessToken'))
                                                }
                                            })];
                                        case 1:
                                            response = _a.sent();
                                            return [2 /*return*/, { user_info: user, read_articles: response.data.read_articles }];
                                    }
                                });
                            }); }))];
                    case 2:
                        userArticlesData = _b.sent();
                        mergedResults = usersData.map(function (user) {
                            var userTests = testsData_1.find(function (test) { return test.user_info.email === user.email; });
                            return userTests ? __assign(__assign({}, userTests), { user_info: user }) : { user_info: user, tests: [] };
                        });
                        sortedUsers = mergedResults.sort(function (a, b) {
                            var lastNameComparison = a.user_info.last_name.localeCompare(b.user_info.last_name);
                            if (lastNameComparison !== 0)
                                return lastNameComparison;
                            return a.user_info.first_name.localeCompare(b.user_info.first_name);
                        });
                        setUsers(usersData);
                        setUserResults(sortedUsers);
                        setUserArticles(userArticlesData);
                        setRoles(rolesData);
                        setAllTests(allTestsData);
                        setAllArticles(allArticlesData);
                        setLoading(false);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _b.sent();
                        console.error("Failed to fetch data:", err_1);
                        setError('Failed to fetch data');
                        setLoading(false);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        fetchData();
    }, []);
    var toggleUserDetails = function (email) {
        setSelectedUserEmail(selectedUserEmail === email ? null : email);
    };
    var resetAttempts = function (userId, testId) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.post("/api/v1/reset-attempts/".concat(userId, "/").concat(testId, "/"), {}, {
                            headers: {
                                Authorization: "Bearer ".concat(localStorage.getItem('accessToken'))
                            }
                        })];
                case 1:
                    response = _a.sent();
                    if (response.status === 200) {
                        notifySuccess('Попытки теста успешно сброшены!');
                    }
                    else {
                        notifyError('Ошибка при сбросе попыток');
                        console.error('Ошибка при сбросе попыток:', response.statusText);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    notifyError('Ошибка при сбросе попыток');
                    console.error('Ошибка:', error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var updateUserRole = function (userId, newRole) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.post("/api/v1/users/role-update/".concat(userId, "/"), { role: newRole }, {
                            headers: {
                                Authorization: "Bearer ".concat(localStorage.getItem('accessToken'))
                            }
                        })];
                case 1:
                    response = _a.sent();
                    if (response.status === 200) {
                        notifySuccess('Роль пользователя успешно обновлена!');
                        setUsers(users.map(function (user) { return user.id === userId ? __assign(__assign({}, user), { role: newRole }) : user; }));
                        setUserResults(userResults.map(function (result) { return result.user_info.id === userId ? __assign(__assign({}, result), { user_info: __assign(__assign({}, result.user_info), { role: newRole }) }) : result; }));
                    }
                    else {
                        notifyError('Ошибка при обновлении роли');
                        console.error('Ошибка при обновлении роли:', response.statusText);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    notifyError('Ошибка при обновлении роли');
                    console.error('Ошибка:', error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var deleteUser = function (email) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.delete('/api/v1/delete-user/', {
                            headers: {
                                Authorization: "Bearer ".concat(localStorage.getItem('accessToken'))
                            },
                            data: { email: email }
                        })];
                case 1:
                    response = _a.sent();
                    if (response.status === 200 || response.status === 204) {
                        notifySuccess('Пользователь успешно удален!');
                        setUsers(users.filter(function (user) { return user.email !== email; }));
                        setUserResults(userResults.filter(function (result) { return result.user_info.email !== email; }));
                    }
                    else {
                        notifyError('Ошибка при удалении пользователя');
                        console.error('Ошибка при удалении пользователя:', response.statusText);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_3 = _a.sent();
                    notifyError('Ошибка при удалении пользователя');
                    console.error('Ошибка:', error_3);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleDeleteUser = function (email) {
        var confirmed = window.confirm('Вы уверены, что хотите удалить пользователя?');
        if (confirmed) {
            deleteUser(email);
        }
    };
    var exportToExcel = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, blob, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get('/api/v1/export_test_results/', {
                            headers: {
                                Authorization: "Bearer ".concat(localStorage.getItem('accessToken'))
                            },
                            responseType: 'blob' // Чтобы axios распознал ответ как Blob
                        })];
                case 1:
                    response = _a.sent();
                    blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    saveAs(blob, 'test_results.xlsx');
                    notifySuccess('Результаты тестов успешно экспортированы!');
                    return [3 /*break*/, 3];
                case 2:
                    error_4 = _a.sent();
                    notifyError('Ошибка при экспорте результатов тестов');
                    console.error('Ошибка при экспорте результатов тестов:', error_4);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    if (loading)
        return _jsx(Skeleton, {});
    if (error)
        return _jsx(Skeleton, {});
    return (_jsxs("div", __assign({ className: styles.wrapper }, { children: [_jsx("button", __assign({ className: styles.exportButton, onClick: exportToExcel }, { children: "\u0412\u044B\u0433\u0440\u0443\u0437\u0438\u0442\u044C \u0432 Excel" })), userResults.map(function (user) {
                var completedTestsCount = user.tests.filter(function (test) { return test.score > 0; }).length;
                var totalTestsCount = allTests.length;
                var userArticlesData = userArticles.find(function (ua) { return ua.user_info.id === user.user_info.id; });
                var readArticlesCount = userArticlesData ? userArticlesData.read_articles.length : 0;
                var totalArticlesCount = allArticles.length;
                return (_jsxs("div", __assign({ className: styles.container }, { children: [_jsxs("div", __assign({ className: styles.testBlock, onClick: function () { return toggleUserDetails(user.user_info.email); } }, { children: [_jsxs("div", __assign({ className: styles.userDetails }, { children: [_jsxs("h4", { children: [user.user_info.last_name, " ", user.user_info.first_name] }), _jsx("select", __assign({ value: user.user_info.role, onChange: function (e) { return updateUserRole(user.user_info.id, e.target.value); }, onClick: function (e) { return e.stopPropagation(); }, className: styles.roleSelect }, { children: roles.map(function (role) { return (_jsx("option", __assign({ value: role.name }, { children: role.name }), role.id)); }) })), _jsx("button", __assign({ className: styles.deleteButton, onClick: function (e) {
                                                e.stopPropagation();
                                                handleDeleteUser(user.user_info.email);
                                            } }, { children: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F" }))] })), _jsxs("span", __assign({ className: styles.testInfo }, { children: ["\u0422\u0435\u0441\u0442\u044B: ", completedTestsCount, "/", totalTestsCount, " | \u0421\u0442\u0430\u0442\u044C\u0438: ", readArticlesCount, "/", totalArticlesCount] }))] })), selectedUserEmail === user.user_info.email && (_jsx("div", __assign({ className: styles.testDetails }, { children: user.tests.length > 0 ? (user.tests.map(function (test) { return (_jsx("div", __assign({ className: styles.testBlock }, { children: _jsxs("div", __assign({ className: styles.wrapper_right_block }, { children: [_jsxs("div", __assign({ className: styles.headline }, { children: [_jsx("h4", { children: test.test_title }), _jsxs("span", { children: ["\u0411\u0430\u043B\u043B\u044B: ", test.score] })] })), _jsx("div", __assign({ className: styles.date }, { children: _jsxs("p", { children: ["\u0414\u0430\u0442\u0430: ", new Date(test.timestamp).toLocaleDateString()] }) })), _jsx("button", __assign({ className: styles.resetButton, onClick: function () { return resetAttempts(test.user_info.id, test.test_id); } }, { children: "\u0421\u0431\u0440\u043E\u0441\u0438\u0442\u044C \u043F\u043E\u043F\u044B\u0442\u043A\u0438" }))] })) }), "".concat(user.user_info.email, "-").concat(test.timestamp))); })) : (_jsx("div", __assign({ className: styles.noTests }, { children: "\u041D\u0435\u0442 \u043F\u0440\u043E\u0439\u0434\u0435\u043D\u043D\u044B\u0445 \u0442\u0435\u0441\u0442\u043E\u0432" }))) })))] }), user.user_info.email));
            })] })));
};
export default UserResults;
