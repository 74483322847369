var _a;
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import navigate from './slice/HeaderSlice';
import { articlesReducer } from './slice/article/articles';
import { authReducer } from './slice/auth/slice';
import { profileReducer } from './slice/profile/slice';
import { authApi } from './api/apiSlice';
var store = configureStore({
    reducer: (_a = {
            navigate: navigate,
            articles: articlesReducer,
            auth: authReducer,
            profile: profileReducer
        },
        _a[authApi.reducerPath] = authApi.reducer,
        _a),
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware().concat(authApi.middleware);
    },
});
export var useAppDispatch = useDispatch;
export default store;
