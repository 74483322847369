var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import * as Sentry from "@sentry/react";
Sentry.init({
    dsn: "https://b4b5bcaf71ca92a89da2b8353d54af30@sentry.neponel.ru/6",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["https://portal.koreanbutik.ru", /^\/api\//],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
var root = ReactDOM.createRoot(document.getElementById('root'));
var queryClient = new QueryClient();
root.render(_jsx(BrowserRouter, { children: _jsx(Provider, __assign({ store: store }, { children: _jsx(QueryClientProvider, __assign({ client: queryClient }, { children: _jsx(App, {}) })) })) }));
