var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import s from './Profile.module.scss';
import Test from './Tests/Test';
import { Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../../NotificationContext';
var Profile = function () {
    var _a, _b;
    var _c = useState(false), modal = _c[0], setModal = _c[1];
    var _d = useState(null), userProfile = _d[0], setUserProfile = _d[1];
    var navigate = useNavigate();
    var _e = useNotification(), notifySuccess = _e.notifySuccess, notifyError = _e.notifyError;
    var _f = useForm({
        mode: 'onChange',
    }), register = _f.register, handleSubmit = _f.handleSubmit, setValue = _f.setValue, errors = _f.formState.errors;
    useEffect(function () {
        var fetchUserProfile = function () { return __awaiter(void 0, void 0, void 0, function () {
            var accessToken, response, data, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        accessToken = localStorage.getItem('accessToken');
                        if (!accessToken) return [3 /*break*/, 7];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        return [4 /*yield*/, fetch('/api/v1/user-profile/', {
                                method: 'GET',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': "Bearer ".concat(accessToken),
                                },
                            })];
                    case 2:
                        response = _a.sent();
                        if (!response.ok) return [3 /*break*/, 4];
                        return [4 /*yield*/, response.json()];
                    case 3:
                        data = _a.sent();
                        setUserProfile(data);
                        // Установка начальных значений формы
                        setValue('email', data.email);
                        setValue('first_name', data.first_name);
                        setValue('last_name', data.last_name);
                        setValue('role', data.role);
                        return [3 /*break*/, 5];
                    case 4:
                        console.error('Ошибка при получении профиля:', response.statusText);
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        error_1 = _a.sent();
                        console.error('Ошибка при отправке запроса на получение профиля:', error_1);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        }); };
        fetchUserProfile();
    }, [setValue]);
    var modalHide = function () {
        setModal(!modal);
    };
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var first_name, last_name, accessToken, response, updatedData_1, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    first_name = data.first_name, last_name = data.last_name;
                    accessToken = localStorage.getItem('accessToken');
                    if (!accessToken) return [3 /*break*/, 7];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, , 7]);
                    return [4 /*yield*/, fetch('/api/v1/user-update/', {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': "Bearer ".concat(accessToken),
                            },
                            body: JSON.stringify({ first_name: first_name, last_name: last_name }),
                        })];
                case 2:
                    response = _a.sent();
                    if (!response.ok) return [3 /*break*/, 4];
                    return [4 /*yield*/, response.json()];
                case 3:
                    updatedData_1 = _a.sent();
                    setUserProfile(function (prevState) { return (__assign(__assign({}, updatedData_1), { role: prevState ? prevState.role : '' })); });
                    modalHide();
                    notifySuccess("Успешное обновление профиля!");
                    return [3 /*break*/, 5];
                case 4:
                    notifyError("\u041E\u0448\u0438\u0431\u043A\u0430 \u043F\u0440\u0438 \u043E\u0431\u043D\u043E\u0432\u043B\u0435\u043D\u0438\u0438 \u043F\u0440\u043E\u0444\u0438\u043B\u044F: ".concat(response.statusText));
                    _a.label = 5;
                case 5: return [3 /*break*/, 7];
                case 6:
                    error_2 = _a.sent();
                    notifyError("\u041E\u0448\u0438\u0431\u043A\u0430 \u043F\u0440\u0438 \u043E\u0442\u043F\u0440\u0430\u0432\u043A\u0435 \u0437\u0430\u043F\u0440\u043E\u0441\u0430 \u043D\u0430 \u043E\u0431\u043D\u043E\u0432\u043B\u0435\u043D\u0438\u0435 \u043F\u0440\u043E\u0444\u0438\u043B\u044F: ".concat(error_2.message));
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var onClickLogout = function () { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, response, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    accessToken = localStorage.getItem('accessToken');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, fetch('/api/v1/logout/', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    response = _a.sent();
                    if (response.ok) {
                        localStorage.removeItem('accessToken');
                        notifySuccess("Вы вышли из аккаунта!");
                        navigate('/login/');
                    }
                    else {
                        notifyError("\u041E\u0448\u0438\u0431\u043A\u0430 \u043F\u0440\u0438 \u0432\u044B\u0445\u043E\u0434\u0435: ".concat(response.statusText));
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_3 = _a.sent();
                    notifyError("\u041E\u0448\u0438\u0431\u043A\u0430 \u043F\u0440\u0438 \u043E\u0442\u043F\u0440\u0430\u0432\u043A\u0435 \u0437\u0430\u043F\u0440\u043E\u0441\u0430 \u043D\u0430 \u0432\u044B\u0445\u043E\u0434: ".concat(error_3.message));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", __assign({ className: s.root }, { children: [_jsx("h2", __assign({ className: 'profileH2' }, { children: "\u041F\u0440\u043E\u0444\u0438\u043B\u044C" })), _jsxs("div", __assign({ className: s.wrapper }, { children: [_jsx("div", __assign({ className: s.sidebar }, { children: _jsx("div", __assign({ className: s.aside }, { children: modal ? (_jsx("div", __assign({ className: s.bottom }, { children: _jsx("div", __assign({ className: s.edit }, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsx("h4", { children: "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C" }), _jsx(TextField, __assign({ label: "\u0418\u043C\u044F", variant: "standard" }, register('first_name', { required: 'Имя обязательно' }), { error: !!errors.first_name, helperText: (_a = errors.first_name) === null || _a === void 0 ? void 0 : _a.message, fullWidth: true })), _jsx(TextField, __assign({ label: "\u0424\u0430\u043C\u0438\u043B\u0438\u044F", variant: "standard" }, register('last_name', { required: 'Фамилия обязательна' }), { error: !!errors.last_name, helperText: (_b = errors.last_name) === null || _b === void 0 ? void 0 : _b.message, fullWidth: true })), _jsx(Button, __assign({ disabled: !Object.keys(errors).length && false, type: "submit", sx: {
                                                    top: '30px',
                                                    background: '#1AB394',
                                                    color: '#000',
                                                    fontWeight: '500',
                                                    textTransform: 'none',
                                                    marginBottom: '20px',
                                                    fontSize: '17px',
                                                    fontFamily: 'comfortaa',
                                                    '&:hover': { background: '#0AA384' },
                                                    marginRight: '15px',
                                                }, variant: "contained" }, { children: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C" })), _jsx(Button, __assign({ sx: {
                                                    top: '30px',
                                                    background: '#1AB394',
                                                    color: '#000',
                                                    fontWeight: '500',
                                                    textTransform: 'none',
                                                    marginBottom: '20px',
                                                    fontSize: '17px',
                                                    fontFamily: 'comfortaa',
                                                    '&:hover': { background: '#0AA384' },
                                                }, variant: "contained", onClick: function () { return modalHide(); } }, { children: "\u041D\u0430\u0437\u0430\u0434" }))] })) })) }))) : (_jsxs("div", __assign({ className: s.top }, { children: [_jsxs("div", __assign({ className: s.user }, { children: [_jsx("h4", { children: "\u0424\u0430\u043C\u0438\u043B\u0438\u044F" }), _jsx("p", { children: userProfile === null || userProfile === void 0 ? void 0 : userProfile.last_name })] })), _jsxs("div", __assign({ className: s.user }, { children: [_jsx("h4", { children: "\u0418\u043C\u044F" }), _jsx("p", { children: userProfile === null || userProfile === void 0 ? void 0 : userProfile.first_name })] })), _jsxs("div", __assign({ className: s.date }, { children: [_jsx("h4", { children: "\u0414\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044C" }), _jsx("p", { children: userProfile === null || userProfile === void 0 ? void 0 : userProfile.role })] })), _jsxs("div", __assign({ className: s.email }, { children: [_jsx("h4", { children: "Email" }), _jsx("p", { children: userProfile === null || userProfile === void 0 ? void 0 : userProfile.email })] })), _jsx(Button, __assign({ onClick: function () { return setModal(true); }, sx: {
                                            background: '#1AB394',
                                            color: '#000',
                                            fontWeight: '500',
                                            textTransform: 'none',
                                            marginBottom: '20px',
                                            fontSize: '16px',
                                            fontFamily: 'comfortaa',
                                            '&:hover': { background: '#0AA384' },
                                        }, variant: "contained", fullWidth: true }, { children: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u0438\u043C\u044F \u0438 \u0444\u0430\u043C\u0438\u043B\u0438\u044E" })), _jsx(Button, __assign({ onClick: onClickLogout, sx: {
                                            background: '#000',
                                            color: '#fff',
                                            fontWeight: '500',
                                            textTransform: 'none',
                                            marginBottom: '20px',
                                            fontSize: '17px',
                                            '&:hover': { background: '#0AA384' },
                                            fontFamily: 'comfortaa',
                                        }, variant: "contained", fullWidth: true }, { children: "\u0412\u044B\u0439\u0442\u0438" })), ((userProfile === null || userProfile === void 0 ? void 0 : userProfile.role) === "Администратор" || (userProfile === null || userProfile === void 0 ? void 0 : userProfile.role) === "Руководитель") && (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ onClick: function () { return navigate('/articlecreate'); }, sx: {
                                                    background: '#000',
                                                    color: '#fff',
                                                    fontWeight: '500',
                                                    textTransform: 'none',
                                                    marginBottom: '20px',
                                                    fontSize: '17px',
                                                    '&:hover': { background: '#0AA384' },
                                                    width: '100%',
                                                    fontFamily: 'comfortaa',
                                                } }, { children: "\u0421\u043E\u0437\u0434\u0430\u0442\u044C \u0441\u0442\u0430\u0442\u044C\u044E" })), _jsx(Button, __assign({ onClick: function () { return navigate('/testcreate'); }, sx: {
                                                    background: '#000',
                                                    color: '#fff',
                                                    fontWeight: '500',
                                                    textTransform: 'none',
                                                    marginBottom: '20px',
                                                    fontSize: '17px',
                                                    '&:hover': { background: '#0AA384' },
                                                    width: '100%',
                                                    fontFamily: 'comfortaa',
                                                } }, { children: "\u0421\u043E\u0437\u0434\u0430\u0442\u044C \u0442\u0435\u0441\u0442" })), _jsx(Button, __assign({ onClick: function () { return navigate('/user-results'); }, sx: {
                                                    background: '#000',
                                                    color: '#fff',
                                                    fontWeight: '500',
                                                    textTransform: 'none',
                                                    marginBottom: '20px',
                                                    fontSize: '17px',
                                                    '&:hover': { background: '#0AA384' },
                                                    width: '100%',
                                                    fontFamily: 'comfortaa',
                                                } }, { children: "\u041F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0438" })), _jsx(Button, __assign({ onClick: function () { return navigate('/report'); }, sx: {
                                                    background: '#000',
                                                    color: '#fff',
                                                    fontWeight: '500',
                                                    textTransform: 'none',
                                                    marginBottom: '20px',
                                                    fontSize: '17px',
                                                    '&:hover': { background: '#0AA384' },
                                                    width: '100%',
                                                    fontFamily: 'comfortaa',
                                                } }, { children: "\u0417\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044C \u043E\u0442\u0447\u0435\u0442" }))] }))] }))) })) })), _jsx("section", __assign({ className: s.test }, { children: _jsx(Test, {}) }))] }))] })));
};
export default Profile;
