import { createSlice } from '@reduxjs/toolkit';
export var initialState = {
    navLinks: [
        { name: 'Главная', url: '/' },
        { name: 'Тесты', url: '/tests' },
        { name: 'Обучение', url: '/articles' },
        // { name: 'Обучение', url: '/leaning' },
        // { name: 'Склад', url: '/stock' },
        // { name: 'Стажёры', url: '/interns' },
    ],
};
var navigate = createSlice({
    name: 'test',
    initialState: initialState,
    reducers: {},
});
// // now available:
// navigate.actions.increment(2);
// // also available:
// navigate.caseReducers.increment(0, { type: 'increment', payload: 5 });
// export const { addNavLinks } = navigate.actions;
export default navigate.reducer;
