var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import s from './Header.module.scss';
import Nav from './Nav/Nav';
import PopapSearch from './PopapSearch/PopapSearch';
var Header = function () {
    var navigate = useNavigate();
    var _a = useState(false), isMenuOpen = _a[0], setIsMenuOpen = _a[1];
    var _b = useState(false), isActivePopap = _b[0], setIsActivePopap = _b[1];
    var _c = useState([]), articles = _c[0], setArticles = _c[1];
    var _d = useState([]), tests = _d[0], setTests = _d[1];
    var urlImageSearch = function () {
        return isActivePopap ? '/img/header/close.svg' : '/img/header/search.svg';
    };
    var navLinks = [
        { name: 'Главная', url: '/' },
        { name: 'Тесты', url: '/tests' },
        { name: 'Обучение', url: '/articles' },
        { name: 'Отчеты', url: '/reports' },
    ];
    var handleUserIconClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    accessToken = window.localStorage.getItem('accessToken');
                    if (!accessToken) return [3 /*break*/, 5];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, fetch('/api/v1/token/verify/', {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ token: accessToken })
                        })];
                case 2:
                    response = _a.sent();
                    if (response.ok) {
                        navigate('/profile/');
                    }
                    else {
                        window.localStorage.removeItem('accessToken');
                        navigate('/login/');
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error('Ошибка при проверке токена:', error_1);
                    navigate('/login/');
                    return [3 /*break*/, 4];
                case 4: return [3 /*break*/, 6];
                case 5:
                    navigate('/login/');
                    _a.label = 6;
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var toggleMenu = function () {
        setIsMenuOpen(function (prev) { return !prev; });
    };
    var closeMenu = function () {
        setIsMenuOpen(false);
    };
    useEffect(function () {
        var handleOutsideClick = function (event) {
            if (!event.composedPath().some(function (el) { var _a; return el instanceof Node && ((_a = el.classList) === null || _a === void 0 ? void 0 : _a.contains(s.container)); })) {
                closeMenu();
            }
        };
        document.addEventListener('click', handleOutsideClick);
        return function () {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);
    useEffect(function () {
        var fetchArticles = function () { return __awaiter(void 0, void 0, void 0, function () {
            var accessToken, response, data, articlesWithUrl, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        accessToken = window.localStorage.getItem('accessToken');
                        if (!(accessToken && isActivePopap)) return [3 /*break*/, 7];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        return [4 /*yield*/, fetch('/api/v1/articles/', {
                                method: 'GET',
                                headers: {
                                    'Authorization': "Bearer ".concat(accessToken),
                                    'Content-Type': 'application/json'
                                }
                            })];
                    case 2:
                        response = _a.sent();
                        if (!response.ok) return [3 /*break*/, 4];
                        return [4 /*yield*/, response.json()];
                    case 3:
                        data = _a.sent();
                        articlesWithUrl = data.map(function (article) { return (__assign(__assign({}, article), { url: "/article/".concat(article.id) // добавляем поле url
                         })); });
                        setArticles(articlesWithUrl);
                        return [3 /*break*/, 5];
                    case 4:
                        console.log('Не удалось загрузить статьи');
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        error_2 = _a.sent();
                        console.error('Ошибка при запросе статей:', error_2);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        }); };
        var fetchTests = function () { return __awaiter(void 0, void 0, void 0, function () {
            var accessToken, response, data, testsWithUrl, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        accessToken = window.localStorage.getItem('accessToken');
                        if (!(accessToken && isActivePopap)) return [3 /*break*/, 7];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        return [4 /*yield*/, fetch('/api/v1/tests/', {
                                method: 'GET',
                                headers: {
                                    'Authorization': "Bearer ".concat(accessToken),
                                    'Content-Type': 'application/json'
                                }
                            })];
                    case 2:
                        response = _a.sent();
                        if (!response.ok) return [3 /*break*/, 4];
                        return [4 /*yield*/, response.json()];
                    case 3:
                        data = _a.sent();
                        testsWithUrl = data.map(function (test) { return (__assign(__assign({}, test), { url: "/test/".concat(test.id) // добавляем поле url
                         })); });
                        setTests(testsWithUrl);
                        return [3 /*break*/, 5];
                    case 4:
                        console.log('Не удалось загрузить тесты');
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        error_3 = _a.sent();
                        console.error('Ошибка при запросе тестов:', error_3);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        }); };
        fetchArticles();
        fetchTests();
    }, [isActivePopap]);
    return (_jsxs(_Fragment, { children: [_jsx("header", { children: _jsxs("div", __assign({ className: "container ".concat(s.container) }, { children: [_jsxs("div", __assign({ className: s.logo }, { children: [_jsx(NavLink, __assign({ to: "/" }, { children: _jsx("img", { src: "/img/logo.svg", alt: "Logo" }) })), _jsx("p", { children: "\u041F\u043E\u0440\u0442\u0430\u043B \u043E\u0431\u0443\u0447\u0435\u043D\u0438\u044F" })] })), _jsxs("div", __assign({ className: isMenuOpen ? "".concat(s.burger, " ").concat(s.open) : s.burger, onClick: toggleMenu }, { children: [_jsx("span", {}), _jsx("span", {}), _jsx("span", {})] })), _jsx("nav", __assign({ className: "".concat(s.nav, " ").concat(isMenuOpen ? s.open : '') }, { children: navLinks.map(function (navLink, i) { return (_jsx(Nav, { navLink: navLink.name, url: navLink.url, onCloseMenu: closeMenu }, i)); }) })), _jsxs("div", __assign({ className: s.profile }, { children: [_jsx("div", __assign({ className: s.search }, { children: _jsx("button", __assign({ onClick: function () { return setIsActivePopap(!isActivePopap); } }, { children: _jsx("img", { src: urlImageSearch() }) })) })), _jsx("button", __assign({ onClick: handleUserIconClick }, { children: _jsx("img", { src: "/img/header/user.svg", alt: "User" }) }))] }))] })) }), _jsx(PopapSearch, { activePopap: isActivePopap, data: __spreadArray(__spreadArray([], articles, true), tests, true), onClose: function () { return setIsActivePopap(false); } })] }));
};
export default Header;
