var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { SurveyModel, Survey, surveyLocalization } from 'survey-react';
import 'survey-react/modern.min.css';
import style from './Test.module.scss';
import { useNotification } from '../../../NotificationContext';
var Test = function () {
    var testId = useParams().testId;
    var _a = useState(null), testData = _a[0], setTestData = _a[1];
    var _b = useState(null), result = _b[0], setResult = _b[1];
    var navigate = useNavigate();
    var _c = useState(null), userRole = _c[0], setUserRole = _c[1];
    var _d = useNotification(), notifySuccess = _d.notifySuccess, notifyError = _d.notifyError;
    useEffect(function () {
        surveyLocalization.locales["ru"].nextText = "Далее";
        surveyLocalization.locales["ru"].prevText = "Назад";
        surveyLocalization.locales["ru"].completeText = "Завершить";
        surveyLocalization.currentLocale = "ru";
        fetchUserProfile();
        var isMounted = true; // Для проверки, что компонент еще смонтирован
        var fetchTest = function () { return __awaiter(void 0, void 0, void 0, function () {
            var accessToken, response, test_1, surveyJson, surveyModel, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        accessToken = localStorage.getItem('accessToken');
                        if (!accessToken) {
                            console.error('Токен доступа отсутствует');
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, fetch("/api/v1/tests/".concat(testId, "/"), {
                                method: 'GET',
                                headers: {
                                    Authorization: "Bearer ".concat(accessToken),
                                },
                            })];
                    case 2:
                        response = _a.sent();
                        if (!response.ok) {
                            throw new Error('Ошибка сетевого запроса за тестами');
                        }
                        return [4 /*yield*/, response.json()];
                    case 3:
                        test_1 = _a.sent();
                        if (isMounted) { // Проверяем, что компонент все еще смонтирован перед установкой состояния
                            surveyJson = convertTestToSurveyJson(test_1);
                            surveyModel = new SurveyModel(surveyJson);
                            surveyModel.onComplete.add(function (sender) {
                                submitTestResults(sender.data, test_1);
                            });
                            setTestData(surveyModel);
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        console.error('Ошибка при получении данных теста:', error_1);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        if (testId) {
            fetchTest();
        }
        // Cleanup для предотвращения ошибок размонтирования
        return function () {
            isMounted = false; // Отмечаем, что компонент был размонтирован
            if (testData) {
                testData.onComplete.clear(); // Удаляем обработчики событий, чтобы избежать утечек памяти
            }
        };
    }, [testId]);
    var convertTestToSurveyJson = function (test) {
        return {
            title: test.title,
            description: test.description,
            pages: test.questions.map(function (question) {
                var _a, _b;
                var correctAnswersCount = question.answers.filter(function (answer) { return answer.is_correct; }).length;
                var elementType = correctAnswersCount > 1 ? 'checkbox' : 'radiogroup';
                return {
                    name: "page_".concat(question.id),
                    elements: [
                        {
                            type: elementType,
                            name: "question_".concat(question.id),
                            title: question.question,
                            isRequired: true,
                            choices: question.answers.map(function (answer) { return ({
                                value: answer.id.toString(),
                                text: answer.answer,
                            }); }),
                            correctAnswer: elementType === 'radiogroup'
                                ? (_b = (_a = question.answers.find(function (answer) { return answer.is_correct; })) === null || _a === void 0 ? void 0 : _a.id.toString()) !== null && _b !== void 0 ? _b : ''
                                : question.answers.filter(function (answer) { return answer.is_correct; }).map(function (answer) { return answer.id.toString(); }),
                        },
                    ],
                };
            }),
        };
    };
    var submitTestResults = function (surveyData, test) { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, totalScore, postData, response, responseData, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    accessToken = localStorage.getItem('accessToken');
                    if (!accessToken) {
                        console.error('Токен доступа отсутствует');
                        notifyError('Токен доступа отсутствует');
                        return [2 /*return*/];
                    }
                    totalScore = 0;
                    test.questions.forEach(function (question) {
                        var elementName = "question_".concat(question.id);
                        var userAnswers = Array.isArray(surveyData[elementName]) ? surveyData[elementName].map(Number) : [Number(surveyData[elementName])];
                        var correctAnswers = question.answers.filter(function (a) { return a.is_correct; }).map(function (a) { return a.id; });
                        // Проверяем вопросы с чекбоксами
                        if (correctAnswers.length > 1) {
                            var userAnswersSet = new Set(userAnswers);
                            var correctAnswersSet_1 = new Set(correctAnswers);
                            if (userAnswersSet.size === correctAnswersSet_1.size && Array.from(userAnswersSet).every(function (value) { return correctAnswersSet_1.has(value); })) {
                                totalScore += 1;
                            }
                        }
                        else {
                            if (userAnswers[0] === correctAnswers[0]) {
                                totalScore += 1;
                            }
                        }
                    });
                    postData = {
                        test_id: test.id,
                        score: totalScore,
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, fetch('/api/v1/test-results/', {
                            method: 'POST',
                            headers: {
                                Authorization: "Bearer ".concat(accessToken),
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(postData),
                        })];
                case 2:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error("\u041E\u0448\u0438\u0431\u043A\u0430 \u043F\u0440\u0438 \u043E\u0442\u043F\u0440\u0430\u0432\u043A\u0435 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u043E\u0432 \u0442\u0435\u0441\u0442\u0430: ".concat(response.status));
                    }
                    return [4 /*yield*/, response.json()];
                case 3:
                    responseData = _a.sent();
                    console.log('Результаты теста успешно сохранены:', responseData);
                    setResult({ score: responseData.score, message: 'Тест завершен!' });
                    notifySuccess('Тест успешно завершен!');
                    return [3 /*break*/, 5];
                case 4:
                    error_2 = _a.sent();
                    notifyError("\u041E\u0448\u0438\u0431\u043A\u0430 \u043F\u0440\u0438 \u043E\u0442\u043F\u0440\u0430\u0432\u043A\u0435 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u043E\u0432 \u0442\u0435\u0441\u0442\u0430: ".concat(error_2));
                    console.error('Ошибка при отправке результатов теста:', error_2);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleEdit = function () {
        navigate("/testupdate/".concat(testId, "/"));
    };
    var handleDelete = function () { return __awaiter(void 0, void 0, void 0, function () {
        var confirmed, token, response, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    confirmed = window.confirm('Вы уверены, что хотите удалить тест?');
                    if (!confirmed)
                        return [2 /*return*/];
                    token = localStorage.getItem('accessToken');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, fetch("/api/v1/tests/delete/".concat(testId), {
                            method: 'DELETE',
                            headers: {
                                Authorization: "Bearer ".concat(token),
                            },
                        })];
                case 2:
                    response = _a.sent();
                    if (response.ok) {
                        notifySuccess('Тест успешно удален!');
                        navigate('/');
                    }
                    else {
                        notifyError('Ошибка при удалении теста');
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_3 = _a.sent();
                    console.error('Ошибка:', error_3);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var fetchUserProfile = function () { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, response, userProfile, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    accessToken = localStorage.getItem('accessToken');
                    if (!accessToken) {
                        console.error('Токен доступа отсутствует');
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, fetch('/api/v1/user-profile/', {
                            method: 'GET',
                            headers: {
                                Authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error('Ошибка при загрузке данных профиля');
                    }
                    return [4 /*yield*/, response.json()];
                case 3:
                    userProfile = _a.sent();
                    setUserRole(userProfile.role);
                    return [3 /*break*/, 5];
                case 4:
                    error_4 = _a.sent();
                    console.error('Ошибка при получении данных профиля:', error_4);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx("div", __assign({ className: style.surveyContainer }, { children: result ? (_jsxs("div", { children: [_jsx("h2", { children: result.message }), _jsxs("p", { children: ["\u0412\u0430\u0448 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442: ", result.score, " \u0431\u0430\u043B\u043B\u043E\u0432"] })] })) : (_jsxs(_Fragment, { children: [testData ? _jsx(Survey, { model: testData }) : _jsx("p", { children: "\u0417\u0430\u0433\u0440\u0443\u0437\u043A\u0430..." }), (userRole === 'Администратор' || userRole === 'Руководитель') && (_jsxs("div", { children: [_jsx("button", __assign({ className: style.editButton, onClick: handleEdit }, { children: "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C" })), _jsx("p", {}), _jsx("button", __assign({ className: style.deleteButton, onClick: handleDelete }, { children: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C" }))] }))] })) })));
};
export default Test;
