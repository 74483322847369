var _a;
import { createSlice } from '@reduxjs/toolkit';
export var isAuthenticated = function () {
    var token = localStorage.getItem('access_token');
    return Boolean(!!token);
};
var initialState = {
    user: null,
    token: null,
};
var authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setUser: function (state, action) {
            var _a = action.payload, user = _a.user, accessToken = _a.accessToken;
            state.user = user;
            state.token = accessToken;
        },
        logOut: function (state) {
            state.user = null;
            state.token = null;
        },
        tokenReceived: function (state, action) {
            state.token = action.payload;
        },
    },
});
// export const selectIsAuth = (state: any) => Boolean(state.auth.user);
export var selectCurrentUser = function (state) { return state.auth.user; };
export var selectCurrentToken = function (state) { return state.auth.token; };
export var setUser = (_a = authSlice.actions, _a.setUser), logOut = _a.logOut, tokenReceived = _a.tokenReceived;
export var authReducer = authSlice.reducer;
