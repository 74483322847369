var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef } from 'react';
import s from './PopapSearch.module.scss';
var PopapSearch = function (_a) {
    var activePopap = _a.activePopap, data = _a.data, onClose = _a.onClose;
    var _b = useState(''), searchTerm = _b[0], setSearchTerm = _b[1];
    var _c = useState([]), searchResults = _c[0], setSearchResults = _c[1];
    var inputRef = useRef(null);
    var handleInputChange = function (event) {
        setSearchTerm(event.target.value);
        if (event.target.value === '') {
            setSearchResults([]);
        }
        else {
            performSearch(event.target.value);
        }
    };
    var performSearch = function (searchText) {
        var results = data.filter(function (item) {
            return item.title.toLowerCase().includes(searchText.toLowerCase());
        });
        setSearchResults(results);
    };
    var handleLinkClick = function () {
        setTimeout(onClose, 100); // Задержка для выполнения перехода по ссылке перед закрытием модального окна
    };
    return (_jsxs("div", __assign({ className: "".concat(s.popap_search, " ").concat(activePopap ? s.activePopap : '') }, { children: [_jsx("button", __assign({ className: s.closeButton, onClick: onClose }, { children: "\u00D7" })), _jsxs("div", __assign({ className: s.block_search }, { children: [_jsx("input", { ref: inputRef, type: "text", placeholder: "\u041F\u043E\u0438\u0441\u043A...", onChange: handleInputChange, value: searchTerm }), _jsx("button", __assign({ onClick: function () { return performSearch(searchTerm); } }, { children: "\u041F\u043E\u0438\u0441\u043A" }))] })), _jsx("div", __assign({ className: s.popap_results }, { children: searchResults.length > 0 ? (_jsx("ul", { children: searchResults.map(function (result) { return (_jsx("li", { children: _jsx("a", __assign({ href: result.url, onClick: handleLinkClick }, { children: _jsx("h4", { children: result.title }) })) }, result.id)); }) })) : (searchTerm && _jsx("p", { children: "\u041D\u0435\u0442 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u043E\u0432" })) }))] })));
};
export default PopapSearch;
