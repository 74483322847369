import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    articles: {
        items: [],
    },
};
var articlesSlice = createSlice({
    name: 'articles',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) { },
});
export var articlesReducer = articlesSlice.reducer;
