import { createSlice } from '@reduxjs/toolkit';
var token = localStorage.getItem('access_token');
var initialState = {
    profile: {
        data: null,
    },
};
var profileSlice = createSlice({
    name: 'profile',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) { },
});
export var selectIsProfile = function (state) { return Boolean(state.profile.data); };
export var profileReducer = profileSlice.reducer;
