var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Survey, StylesManager, Model, surveyLocalization } from 'survey-react';
import 'survey-react/survey.css';
import style from './TestCreate.module.scss';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../../../NotificationContext';
StylesManager.applyTheme("default");
var TestCreate = function () {
    var _a = useState([]), categories = _a[0], setCategories = _a[1];
    var accessToken = localStorage.getItem('accessToken');
    var navigate = useNavigate();
    var _b = useNotification(), notifySuccess = _b.notifySuccess, notifyError = _b.notifyError;
    useEffect(function () {
        surveyLocalization.currentLocale = "ru";
        var fetchCategories = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, data, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, fetch('/api/v1/categories/', {
                                method: 'GET',
                                headers: {
                                    'Authorization': "Bearer ".concat(accessToken),
                                    'Content-Type': 'application/json'
                                }
                            })];
                    case 1:
                        response = _a.sent();
                        if (!response.ok) {
                            throw new Error("HTTP error! status: ".concat(response.status));
                        }
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        setCategories(data.map(function (item) { return ({ text: item.name, value: item.id }); }));
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error("Ошибка при получении категорий:", error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        if (accessToken) {
            fetchCategories();
        }
        else {
            console.error("AccessToken не найден в localStorage.");
        }
    }, [accessToken]);
    var surveyJson = {
        title: "Заполните форму для создания теста",
        description: "",
        elements: [
            { type: "text", name: "title", title: "Название теста", isRequired: true },
            { type: "comment", name: "description", title: "Описание теста" },
            { type: "dropdown", name: "category", title: "Категория", choices: categories, isRequired: true },
            {
                type: "paneldynamic", name: "questions", title: "Вопросы",
                templateElements: [
                    { type: "text", name: "question", title: "Текст вопроса", isRequired: true },
                    {
                        type: "matrixdynamic", name: "answers", title: "Ответы",
                        columns: [
                            { name: "answer", title: "Текст ответа", cellType: "text", isRequired: true },
                            { name: "is_correct", title: "Правильный ответ?", cellType: "boolean" }
                        ],
                        rowCount: 1,
                        addRowText: "Добавить ответ",
                        removeRowText: "Удалить ответ"
                    }
                ],
                panelCount: 1,
                panelAddText: "Добавить вопрос",
                panelRemoveText: "Удалить вопрос"
            }
        ]
    };
    var survey = new Model(surveyJson);
    survey.completedHtml = "Спасибо за ваш ответ!";
    survey.completeText = "Создать тест";
    survey.startSurveyText = "Начать тест";
    survey.onComplete.add(function (sender) { return __awaiter(void 0, void 0, void 0, function () {
        var data, response, responseData, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    data = sender.data;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, fetch('/api/v1/tests/create/', {
                            method: 'POST',
                            headers: {
                                'Authorization': "Bearer ".concat(accessToken),
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(data)
                        })];
                case 2:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error("HTTP error! status: ".concat(response.status));
                    }
                    return [4 /*yield*/, response.json()];
                case 3:
                    responseData = _a.sent();
                    notifySuccess("Тест успешно создан!");
                    navigate('/tests');
                    return [3 /*break*/, 5];
                case 4:
                    error_2 = _a.sent();
                    notifyError("Ошибка при создании теста");
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); });
    return (_jsxs("div", { children: [_jsx("h2", __assign({ className: style.testCreate }, { children: "\u0421\u043E\u0437\u0434\u0430\u043D\u0438\u0435 \u0442\u0435\u0441\u0442\u0430" })), _jsx(Survey, { model: survey })] }));
};
export default TestCreate;
